.parentContainer {

}


.previewBox {
    width: 100%;
    position: relative;
    border: 1px solid #6F6F6F!important;
    border-radius: 4px;
    background-color: #FFFFFF;
    font-family: SanFranciscoPro;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-size: 1rem;
    cursor: text;
}


.previewBox:hover{
    background: #F5F5F5;
    box-shadow: 0 0 4px 0 #00000040;
}
