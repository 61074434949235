
.logo-home:hover{
  transform: translate(1px, 1px);
}

@media only screen and (max-width: 1199px) {
  :root {
   --navbar-height: 52px;
   --main-height: calc(100vh - 52px);
  }
}

@media only screen and (min-width: 1200px) {
  :root {
   --navbar-height: 52px;
   --main-height: calc(100vh - 52px);
  }
}

.logo-icon {
 margin: 0 !important;
 /* background-color: #0277ad !important; */
 padding-right: 0 !important;
}

#first-navbar {
  background: linear-gradient(90.07deg, #031434 12.79%, #2A4E83 42.74%);
  padding: 0 !important;
}

#viewer-navbar {
  height: 75px;
  /* background-color: #E6F2F7; */
  padding: 0 10px !important;
}

.first-navbar-mobile {
 background-color: #0277ad;
 width: 100%;
 margin: 0px 0px 0px 0px;
 padding: 10px 10px 10px 10px;
 position: absolute;
 top: 0;
}

/* #second-navbar {
 background: rgba(250, 252, 253, 1);
 border: 1px solid #d2d2d2;
 box-sizing: border-box;
 box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
} */

.navbar{
 z-index: 2;
}

.navbar-unit {
 display: flex;
 justify-content: center;
 align-items: center;
}

.viewerSideBarProfileRow {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: var( --side-bar-width);
  min-height: 75px;
  /* background: #E6F2F7; */
  /* border-bottom: 1px solid #D2D2D2; */
}

.viewerNavBarUserName {
    font-style: normal;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  width: 34px;
  height: 34px;
  color: #FFFFFF !important;
  background: #42296E;
  border-color: #42296E;
  border-radius: 17px;
  letter-spacing: 0.035em;
}

.viewerSideBarUserName {
    font-style: normal;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  width: 52px !important;
  height: 52px !important;
  color: #FFFFFF !important;
  background: #0277AD;
  border-color: #0277AD;
  border-radius: 26px;
  letter-spacing: 0.035em;
  margin-left: 10px;
}

.viewerSideBarUserNameText {
    font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #FFFFFF;
  margin-left: 10px;
  letter-spacing: 0.035em;
  /* max-width: calc(100% - 102px); */
}

.viewerSideBarLogout {
  padding: 0px 10px 0px 10px;
}

.crossIcon {
  margin-left: auto;
  padding-right: 10px;
}

.navBarUserName {
  font-family: "SanFranciscoPro";
  font-style: normal;
  font-weight: 600;
  font-size: 1.056rem;
  color: #FFFFFF !important;
  margin: auto; 
}
.navDropdownText {
  font-family: "SanFranciscoPro";
  font-style: normal;
  font-weight: 600;
  font-size: 1.056rem;
  color: #FFFFFF !important;
}

.navbar .dropdown-menu{
  min-width: -webkit-fill-available;
  width: 100%;
}

.dropdown-menu:hover {
  cursor: pointer;
}


.dropdown-menu {
    border: none!important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


.dropdown-item.active {
    background-color: rgba(24, 144, 255, 0.15)!important;
}

.tooltip-inner {
    background-color: #6C6C6C !important;
}

.tooltip-arrow::before {
    border-left: 8px solid transparent!important;
    border-right: 8px solid transparent!important;
    border-top: 8px solid #6C6C6C !important;
}


.active .navDropdownText {
 color: rgba(2, 119, 173, 1) !important;
 border: none !important;
}

.dropdown-toggle::after {
 display: none !important;
}

.navbar-text {
 font-family: 'SanFranciscoPro';
 font-style: normal;
 font-weight: 700;
 opacity: 0.8;
 font-size: 22px;
 line-height: 22px;
 color: #ffffff !important;
 text-shadow: 0px 1.53308px 1.53308px rgba(0, 0, 0, 0.25);
}

.navbar-engage {
  display: flex !important;
  justify-content: center;
}

.navbar-middle-text-with-border {
  font-family: 'SanFranciscoPro';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  height: 100%;
  color: #ffffff !important;
  text-shadow: 0px 1.53308px 1.53308px rgba(0, 0, 0, 0.25);
  margin: 0px;
  text-decoration: none;
  padding: 8px;
  border-right: 1px solid #FFFFFF;
 }

 .navbar-middle-text {
  font-family: 'SanFranciscoPro';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  height: 100%;
  color: #ffffff !important;
  text-shadow: 0px 1.53308px 1.53308px rgba(0, 0, 0, 0.25);
  margin: 0px;
  text-decoration: none;
  padding: 8px;
 }

 .navbar-middle-text-with-border:hover {
  background-color: #617A8B;
 }

.navbar-middle-text:hover {
  background-color: #617A8B;
 }


.navbar-text-mobile {
 margin: 0px;
 padding: 0px 0px 0px 50px;
 font-style: normal;
 font-weight: 600;
 font-size: 1.6rem;
 color: #ffffff !important;
 text-shadow: 0px 1.53308px 1.53308px rgba(0, 0, 0, 0.25);
 
}

.margin-left-1 {
 margin-left: 1rem !important ;
}

.max-width {
 max-width: 100% !important;
 padding-right: 0 !important;
 margin-right: 0 !important;
}

.variable-list-name {
 color: #26a69a;
 text-transform: none;
 font-size: 1rem;
 font-weight: 700;
}

.vertical-center {
 margin-left: auto;
 margin-right: auto;
}

.wrong-ans .modal-backdrop.show {
  background: #000000;
  opacity: 0.65 !important;
}

.correct-ans .modal-backdrop.show {
  background: #000000;
  opacity: 0.65 !important;
}

.not-graded .modal-backdrop.show {
  background: #000000;
  opacity: 0.65 !important;
}

.wrong-ans .modal-content {
  border-radius: 17.2881px !important;
  border: none;
}

.correct-ans .modal-content {
  border-radius: 17.2881px !important;
  border: none;
}

.not-graded .modal-content {
  border-radius: 17.2881px !important;
  border: none;
}

@media only screen and (min-width: 950px) {
  .not-graded .modal-dialog {
    width: 650px !important;
  }

  .not-graded .modal-content {
    width: 650px !important;
  }
}

.cursor-pointer {
 cursor: pointer;
}

.cursor-default{
 cursor: default;
}

.horizontal-center {
 margin-top: auto;
 margin-bottom: auto;
}

.mt-1_5 {
 margin-top: 0.65rem !important;
}

.form {
 display: flex;
 height: 100%;
}
.form input[type="text"] {
 flex: 1;
 text-align: left;
 padding-bottom: 7px;
 width: 100%;
}

input:focus {
 outline: none;
}

.round {
 position: relative;
 height: 100%;
}

.round label {
 background-color: #fff;
 border: 1px solid #ccc;
 border-radius: 50%;
 cursor: pointer;
 left: 1.5vh;
 position: absolute;
 top: 0.2rem;
 width: 28px;
 height: 28px;
}

.img-wrap img {
 float: left;
}


@media only screen and (max-width: 950px) {
  .sideNavbar {
      position: absolute;
      left: 0;
      top: 0;
      min-width: 250px;
      overflow-x: hidden;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      height: 100vh;
      background-color: #163E4A;
      transition: all 0.5s ease;
      box-sizing: border-box;
      z-index: 2000;
      width: 50px;
      box-sizing: border-box;
  }
}

@media only screen and (min-width: 951px) {
  .sideNavbar {
      position: absolute;
      left: 0;
      overflow-x: hidden;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      height: var(--main-height);
      background-color: #163E4A;
      transition: all 0.5s ease;
      box-sizing: border-box;
      z-index: 2000;
      width: 50px;
      box-sizing: border-box;
  }
}

.sideNavbar:hover{
  z-index: 2000;
  width: 250px;
  box-sizing: border-box;
}

.mobileSideNavbar{
  height: 100vh;
  background-color: #163E4A;
  transition: all 0.5s ease;
  box-sizing: border-box;
  z-index: 2000;
  width: 2.5%;
  box-sizing: border-box;
}

.mobileSideNavbar:hover{
  z-index: 2000;
  width: 250px;
  box-sizing: border-box;
}

.sidebarTab{
  text-align: left;
}

.sidebarTab:hover{
  border: 2px solid #FFFFFF;
}

.sidebarNavItem{
  width : 250px;
  padding-left : 0 !important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.sidebarMenu {
 height: var(--main-height);
 display: flex;
 flex-direction: column;
 justify-content: space-between;
}

.sidebarLink {
 display: flex;
 align-items: center;
 width : 250px;
 height: 55px;
 color : white;
 white-space : nowrap;
 text-decoration : none;
 font-family : SanFranciscoPro;
 font-weight : bold;
 line-height: 55px;
 border: 2px solid #163E4A;
 padding: 0px;
}

.sidebarLink:hover {
 color : white;
 border: 2px solid #FFFFFF;
}

.mobileSidebarLink {
  color: #331F56;
  font-family: SanFranciscoPro;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.677px; /* 169.792% */
  letter-spacing: 0.849px;
}

.mobileSidebarLink:hover {
  color: #000000;
  background-color: #96C8DF;
}

.createOption {
  display: flex;
  align-items: center;
  width : 250px;
  height: 55px;
  color : black;
  white-space : nowrap;
  text-decoration : none;
  font-family : SanFranciscoPro;
  font-weight : bold;
  line-height: 55px;
  border: 2px solid #FFFFFF;
 }
 
 .createOption:hover {
  color : white;
  background-color: #367E96 !important;
  border: 2px solid #FFFFFF;
 }
#first-nav-container {
    height: var(--navbar-height);
}

@media only screen and (max-width: 950px) {
 .round label {
   left: 1vh;
   top: 0.5vh;
 }

 .round .ans-label {
   left: -1.3rem;
   top: 0.12rem;
 }

 .navbar-brand {
   margin-right: 0.5px !important;
 }

 #first-nav-container {
   padding: 0 !important;
 }
}


.round label:after {
 border: 2px solid #fff;
 border-top: none;
 border-right: none;
 content: "";
 height: 6px;
 left: 7px;
 opacity: 0;
 position: absolute;
 top: 8px;
 transform: rotate(-45deg);
 width: 12px;
}

.round input[type="radio"] {
 visibility: hidden;
 width: 0px;
 height: 0px;
}

.round input[type="radio"]:checked + label {
 background-color: #66bb6a;
 border-color: #66bb6a;
}

.round input[type="radio"]:checked + label:after {
 opacity: 1;
}

.text-left {
 text-align: left;
}

.expand {
 width: 75%;
 text-transform: uppercase;
}

@media only screen and (max-width: 300px) {
 .expand {
   width: 65%;
 }
}

.custom-button {
 width: 150px;
 height: 40px;
}

.dark-blue-text {
 color: #39566b !important;
 font-family: "SanFranciscoPro";
 font-size: 0.875rem !important;
 font-weight: 700;
}

/* Position and sizing of burger button */
.bm-burger-button {
 position: relative;
 width: 36px;
 height: 30px;
 left: 36px;
 top: 36px;
}

.publishButton {
 position: absolute;
 right: 15px;
 color: rgb(196, 202, 209) !important;
 font-family: "SanFranciscoPro";
}

.center {
 text-align: center;
}

.question-title-box {
 margin-top: 0.25rem !important;
 width: 110%;
 font-weight: 400;
 font-size: 20px;
}

.wrapper .caption .button:hover {
 background: #ffd346;
 color: #000;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
 background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
 background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
 height: 24px;
 width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
 background: #bdc3c7;
}

/*
 Sidebar wrapper styles
 Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
 */
.bm-menu-wrap {
 position: fixed;
 height: 100%;
}

/* General sidebar styles */
.bm-menu {
 background: #373a47;
 padding: 1.5em 1.5em 0;
 font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
 fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
 color: #b8b7ad;
 padding: 0.8em;
}

/* Individual item */
.bm-item {
 display: inline-block;
 color: #d1d1d1;
 margin-bottom: 10px;
 text-align: left;
 text-decoration: none;
 transition: color 0.2s;
}

.bm-item:hover {
 color: #ffffff;
}

/* Styling of overlay */
.bm-overlay {
 background: rgba(0, 0, 0, 0.3);
}

label.radio {
 cursor: pointer;
}

label.radio input {
 position: absolute;
 top: 0;
 left: 0;
 visibility: hidden;
 pointer-events: none;
}

.correct-option {
 width: 100% !important;
 border-style: none !important;
 height: 5vh !important;
 color: Red !important;
}

.option {
 display: inline-block;
 width: 100px;
 text-align: center;
 border-radius: 3px;
 margin-top: 7px;
}

label.radio span {
 padding: 4px 0px;
 border: 1px solid red;
 display: inline-block;
 color: red;
 width: 100px;
 text-align: center;
 border-radius: 3px;
 margin-top: 7px;
 text-transform: uppercase;
}

.radio-button {
 float: left;
 width: 20%;
 height: 100%;
}

.input-section {
 display: block;
 float: left;
 width: 80%;
 height: 100%;
}
.input-section > input {
 /* HIDE RADIO */
 visibility: hidden; /* Makes input not-clickable */
 position: absolute; /* Remove input from document flow */
}

.question-details-column {
 flex: 0 0 auto !important;
 width: 87.33333333% !important;
}

.sidebar-icon-container {
 flex: 0 0 auto !important;
 width: 16.666667% !important;
}

@media screen and (min-width: 1100px) and (max-width: 1600px) {
 .question-details-column {
   width: 80% !important;
 }

 .sidebar-icon-container {
   width: 20.666667% !important;
 }
}

@media screen and (max-width: 1100px) {
 .question-details-column {
   width: 70% !important;
 }

 .sidebar-icon-container {
   width: 20.666667% !important;
 }
}

.input-text {
 box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
 padding-bottom: 10px;
}

.short-answer {
 width: 50%;
 margin: auto;
 margin-top: 1rem !important;
}

input {
 border-top-style: hidden;
 border-right-style: hidden;
 border-left-style: hidden;
 border-bottom-style: hidden;
}

.no-outline:focus {
 outline: none;
}

.flex-container {
 display: inline-flex;
 writing-mode: horizontal-tb;
 flex-wrap: wrap;
 align-content: flex-start;
 width: 100%;
}

.ans {
 min-height: 3rem;
 writing-mode: horizontal-tb;
 width: 45% !important;
 height: 5vh;
 margin: 20px;
}

.ans:focus {
 background-color: #000;
}

@media only screen and (max-width: 950px) {
 .ans {
   margin-left: 10px !important;
   width: 100% !important;
   height: 20%;
 }

 .question-title-box {
   font-size: 16px;
 }
}

.btn:focus {
 outline: 0 !important;
 box-shadow: none !important;
}

.btn:active {
 outline: 0 !important;
 box-shadow: none !important;
}

.editable:empty:before {
 content: attr(data-placeholder);
}

/* Popup style */
.popup-box {
 position: fixed;
 background: #00000050;
 width: 100%;
 height: 100vh;
 top: 0;
 left: 0;
}

.box {
 position: relative;
 width: 40%;
 margin: 0 auto;
 height: auto;
 max-height: 70vh;
 margin-top: calc(100vh - 85vh - 20px);
 background: #fff;
 border-radius: 4px;
 padding: 20px;
 border: 1px solid #999;
 overflow: auto;
}

.cursor {
 cursor: pointer;
}

.box-shadow {
 box-shadow: rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px;
}

.cursor:hover {
 box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
 -webkit-transform: scale(1.05, 1.05);
 transform: scale(1.05, 1.05);
}

.close-icon {
 content: "x";
 cursor: pointer;
 position: fixed;
 right: calc(15% - 30px);
 top: calc(100vh - 85vh - 33px);
 background: #ededed;
 width: 25px;
 height: 25px;
 border-radius: 50%;
 line-height: 20px;
 text-align: center;
 border: 1px solid #999;
 font-size: 20px;
}

.radio-none {
 display: none;
}

.bigger-font {
 font-size: 20px !important;
}

.truncate {
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}

.small-ans {
 border-radius: 3px;
 border: 1px solid red;
 writing-mode: horizontal-tb;
 width: 90%;
 margin: 5px;
 margin-left: auto;
 margin-right: auto;
 overflow: auto;
}

.mini-container {
 padding-top: 20px;
}

@media screen and (max-width: 1500px) {
 .mini-container {
   padding-top: 20px;
 }
}

.small-text {
 font-size: 12px !important;
}

.splitScreen {
 display: flex;
 flex-direction: row;
 text-align: center;
}

.splitScreenColumn {
 display: flex;
 flex-direction: column;
 text-align: center;
}

.topPane,
.bottomPane {
 margin: auto;
 width: 50%;
 padding: 10px;
 padding-top: 2vh;
}

@media only screen and (max-width: 950px) {
 .topPane,
 .bottomPane {
   padding-top: 5vh;
 }
}

#mini-question-footer {
 padding-top: 0.7rem;
}

.mini-question-button {
 height: 2rem;
 width: 8rem;
}

.dropdown-container {
 background: #fefefe;
 border: 1px solid #D0D0D0;
 border-radius: 3px 3px 0px 0px;
}

.dropdownText {
    font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin: 7px 0px 7px 0px;
  margin-left: 8px;
  color: #000000;
}

.dropdownIcon {
  width: 20px;
  height: 20px;
  margin: 7px 0px 7px 0px;
  display: inline;
  cursor: pointer;
}

.dropdown-item {
  width: 100%;
    font-style: normal;
  font-weight: 400 !important;
  font-size: 13px;
  line-height: 20px;
  color: #000000 !important;
  box-sizing: border-box;
  border-radius: 0px;
}

.right-align {
  margin-left: 24px !important;
  margin-right: 0 !important;
}

.vertical-line{
  width: 65px;
  height: 0px;

  border: 1.06642px solid #FFFFFF;
  transform: rotate(-90deg)
}

.dropdown-poll-type-first {
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  border-radius: 3px 3px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 15px 8px 15px;
  cursor: pointer;
}

.dropdown-poll-type-select {
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  border-radius: 3px 3px 0px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 15px 8px 15px;
}

.dropdown-poll-type-select:hover {
  background-color: #F3F3F3;
  cursor: pointer;
}

.dropdown-poll-type-opp {
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  border-top: 0px;
  border-radius: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 15px 8px 15px;
}

.dropdown-poll-type-opp:hover {
  background-color: #F3F3F3;
  cursor: pointer;
}

.dropdown-text-format {
  width: 100%;
    font-style: normal;
  font-weight: 400;
  font-size: 1.042rem;
  line-height: 13px;
  color: #000000;
}

.filter-menu {
  /* width: 100%; */
  /* height: 100%; */
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5px 5px 5px;
  z-index: 1000;
  position: relative;
}

.option-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* margin: 0 0 10px 0; */
}

.option-filter-column {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  /* gap: 5px; */
  margin: 0px 10px;
  padding-bottom: 5px;
}

.option-title {
    font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: #000000;
  margin-bottom: 5px;
}

.option-row {
  vertical-align: middle;
  gap: 5px;
  width: 100%;
}

.option-checkbox {
  margin-right: 5px;
}

.option-checkbox:focus-visible {
  outline: 1px solid black;
}

.option-txt {
    font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.date-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 10px; */
}

.date-filter-column {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%;
  /* gap: 5px; */
  margin: 0px 10px;
}

.date-picker-title {
    font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #000000;
  margin: 5px 0;
}

.date-picker-input {
  /* padding: 0px 10px; */
  width: 160px;
}

.filter-buttons {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: flex-end !important; */
  align-self: flex-end;
  gap: 10px;
  margin: 15px 5px 0px 0px;
}